<template>
  <v-app>
    <v-main>
      <video-background
      src="/AmpersandLogo720p.mp4"
      poster="/libretto_poster.jpg"
      style="height: 100vh;"
      overlay="linear-gradient(45deg,#ffffff30,#9999996b)"
      >
      <HelloWorld/>
      </video-background>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld'

export default {
  name: 'App',

  components: {
    HelloWorld
  },

  data: () => ({
    //
  })
}
</script>

<style>
.video-wrapper, .video-buffering {
  position: fixed !important;
}
.vue-responsive-videobg {
  overflow: auto !important;
}
</style>
