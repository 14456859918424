import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { Plugin } from 'vue-responsive-video-background-player'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false
Vue.use(Plugin)
Vue.use(VueGtag, {
  config: { id: 'G-2PLKEQX7MT' }
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
